import React from 'react';
import { Box, Text, Link } from 'rebass'
import backgroundImage from './header.png'
import styled from 'styled-components'

const BackgroundBox = styled(Box)`
  background-image: url(${backgroundImage});
  background-color: rgb(91, 112, 143, 0.5);
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding: 8px;
`

const NiceText = styled(Text)`
  margin-top: 10%;
  display:inline-block; 
  color: white;
  background-color: black;
  text-align: center;
  font-size: 24px;
  text-decoration: none;
`

function App() {
  return (
    <BackgroundBox>
      <Box>
        <Link href="https://itunes.apple.com/us/app/aphera-music-instrument-suite/id1146013329?mt=8">
          <NiceText>
            Aphera Music Instrument Suite
          </NiceText>
        </Link>
      </Box>
    </BackgroundBox>
  );
}

export default App;
